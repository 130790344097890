import React, {useState, useEffect, useRef} from 'react';
import {
  Button,
  IconButton,
  Drawer,
  makeStyles,
  Fade,
  useMediaQuery,
  Hidden
} from '@material-ui/core';
import {useAppState} from '../../state';
import {ReactComponent as ArrowLeft} from '../../assets/icons/arrow-left.svg';
import {ReactComponent as ArrowRightWhite} from '../../assets/icons/arrow-right-white.svg';
import {ReactComponent as IconActionHome} from '../../assets/icons/icon-action-home.svg';
import {ReactComponent as IconExpand} from '../../assets/icons/icon-navigation-expand-less.svg';
import {ReactComponent as IconExpandDown} from '../../assets/icons/icon-navigation-expand-more.svg';
import SidebarSlide from './SidebarSlide';
import SidebarClose from './SidebarClose';
import {HEADER_HEIGHT} from '../Header/Header';

export const SIDEBAR_WIDTH = {
  xs: '500px',
  xsNr: 500,
  lg: '560px',
  lgNr: 560
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    zIndex: 1,
    height: 0,
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      '&, & $drawerPaper': {
        width: SIDEBAR_WIDTH.xs,
        inset: '0 0 0 auto !important'
      }
    },
    [theme.breakpoints.up('lg')]: {
      '&, & $drawerPaper': {
        width: SIDEBAR_WIDTH.lg
      }
    }
  },
  drawerPaper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.grey[50],
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      '&:not($drawerPaperExpanded)': {
        borderTopLeftRadius: theme.spacing(1),
        borderTopRightRadius: theme.spacing(1)
      }
    },
    [theme.breakpoints.up('md')]: {
      height: '100%'
    }
  },
  drawerPaperExpanded: {
    [theme.breakpoints.down('sm')]: {
      top: 0
    }
  },
  header: {
    position: 'fixed',
    [theme.breakpoints.up('md')]: {
      position: 'absolute'
    }
  },
  footer: {
    display: 'block',
    position: 'relative',
    width: '100%',
    minHeight: theme.spacing(8.5),
    [theme.breakpoints.up('md')]: {
      minHeight: theme.spacing(10.5)
    }
  },
  footerItem: {
    margin: theme.spacing(2, 0),
    position: 'absolute',
    bottom: 0,
    '&[data-position="left"]': {
      left: theme.spacing(2)
    },
    '&[data-position="top-left"]': {
      left: theme.spacing(2),
      bottom: 'auto',
      top: 0,
      zIndex: 1
    },
    '&[data-position="right"]': {
      right: theme.spacing(2)
    },
    '&[data-position="center"]': {
      left: '50%',
      transform: 'translateX(-50%)',
      margin: theme.spacing(2, 0)
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(3, 0),
      '&[data-position="left"]': {
        left: theme.spacing(3)
      },
      '&[data-position="top-left"]': {
        left: theme.spacing(3)
      },
      '&[data-position="right"]': {
        right: theme.spacing(3)
      },
      '&[data-position="center"]': {
        margin: theme.spacing(3, 0)
      }
    }
  },
  dots: {
    display: 'flex',
    alignItems: 'center',
    minHeight: theme.spacing(4.5)
  },
  dot: {
    width: theme.spacing(1),
    height: theme.spacing(1),
    margin: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.text.disabled,
    transition: theme.transitions.create('background-color', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short
    }),
    '&[data-active="true"]': {
      backgroundColor: theme.palette.primary.main
    }
  },
  slides: {
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    minHeight: theme.spacing(10)
  },
  headerPrimary: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    width: '100%',
    zIndex: 2,
    display: 'none',
    bottom: `calc(100% - ${HEADER_HEIGHT}px)`,
    height: 0,
    backgroundColor: theme.palette.grey[50],
    overflow: 'hidden'
  },
  headerPrimaryShown: {
    display: 'block'
  },
  headerPrimaryClose: {
    position: 'absolute',
    margin: theme.spacing(2.25, 2),
    bottom: 0,
    left: 0,
    '& svg path': {
      fill: theme.palette.primary.main
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(3)
    }
  },
  headerPrimaryItem: {
    top: 'auto',
    bottom: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      bottom: 0
    }
  },
  iconButtonContained: {
    '&, &:hover, &:focus, &:active': {
      backgroundColor: theme.palette.primary.main
    }
  },
  footerClose: {
    position: 'fixed',
    [theme.breakpoints.up('md')]: {
      position: 'absolute'
    }
  },
  swipeUpRoot: {
    position: 'absolute',
    top: theme.spacing(2.5),
    right: theme.spacing(2.5)
  }
}));

const Sidebar = () => {
  const classes = useStyles();
  const {next, prev, reset, currentSlide, slidesNumber} = useAppState();
  const [headerPrimaryHeight, setHeaderPrimaryHeight] = useState(0);
  const [mobileExpanded, setMobileExpanded] = useState(false);
  const currentCardRef = useRef();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const slideShrinked = !mobileExpanded && !isDesktop;

  useEffect(() => {
    if (currentCardRef?.current) {
      const item = currentCardRef.current;
      const itemContent = item.querySelector('[data-content="true"]');

      const scrollListener = () => {
        const contentTop = itemContent.getBoundingClientRect().top;
        setHeaderPrimaryHeight(
          contentTop > HEADER_HEIGHT
            ? 0
            : contentTop > 0
            ? HEADER_HEIGHT - contentTop
            : HEADER_HEIGHT
        );
      };
      item.addEventListener('scroll', scrollListener);
      scrollListener();
      return () => {
        item?.removeEventListener('scroll', scrollListener);
      };
    }
  }, [currentCardRef.current, setHeaderPrimaryHeight]);

  useEffect(() => {
    if (!currentSlide || isDesktop) {
      setMobileExpanded(false);
    }
  }, [currentSlide, setMobileExpanded, isDesktop]);

  let s = 0;

  return (
    <Drawer
      className={classes.drawer}
      variant="temporary"
      anchor={isDesktop ? 'right' : 'bottom'}
      open={currentSlide > 0}
      classes={{
        paper: [classes.drawerPaper, mobileExpanded ? classes.drawerPaperExpanded : ''].join(' ')
      }}
      hideBackdrop={true}
    >
      <div
        className={[
          classes.headerPrimary,
          headerPrimaryHeight > 0 ? classes.headerPrimaryShown : ''
        ].join(' ')}
        style={{height: headerPrimaryHeight}}
      >
        {mobileExpanded ? (
          <IconButton
            className={classes.headerPrimaryClose}
            onClick={() => {
              setMobileExpanded(false);
            }}
          >
            <IconExpandDown />
          </IconButton>
        ) : (
          <SidebarClose className={classes.headerPrimaryClose} primary={true} />
        )}
      </div>

      <div className={[classes.footerItem, classes.footerClose].join(' ')} data-position="top-left">
        {mobileExpanded ? (
          <IconButton
            onClick={() => {
              setMobileExpanded(false);
            }}
          >
            <IconExpandDown />
          </IconButton>
        ) : (
          <SidebarClose />
        )}
      </div>

      <div className={classes.slides}>
        <SidebarSlide
          slide={++s}
          shrinked={slideShrinked}
          {...(currentSlide === s && {ref: currentCardRef})}
          title="100X* performance boost with spatial indices
    "
          subtitle="Spatial indices enable huge speedups when it comes to spatial algorithm. But how is this all rendered?"
          text="*as measured by Alberto"
        />
        <SidebarSlide
          slide={++s}
          shrinked={slideShrinked}
          {...(currentSlide === s && {ref: currentCardRef})}
          title="Rendering using spatial indices"
          subtitle="Rather than using geometries (points/lines/polygons) to describe spatial component of data, use a spatial index"
          text="Natively understood by deck.gl, no need transform to GeoJSON or other format"
          code={
`// Data format
[
  {id: "023010330", value: 0.2},
  {id: "023010331", value: 0.7},
  {id: "023010332", value: 0.1},
  {id: "023010333", value: 0.5},
  ...
]`}
        />
        <SidebarSlide
          slide={++s}
          shrinked={slideShrinked}
          {...(currentSlide === s && {ref: currentCardRef})}
          title="Styling spatial indices"
          subtitle="Visualizing properties is as normal, just map using accessor"
          text="Can use one parameter to style color, and another for extrusion"
          code={
`
// Data format
[
  {id: "012", c: [255, 0, 0], t: 12},
  {id: "013", c: [0, 255, 0], t: 15},
  ...
]

new QuadkeyLayer({
  data: 'quadkeys.json',

  getFillColor: ({c}) => c,
  getElevation: ({t}) => t,
});`}
        />
        <SidebarSlide
          slide={++s}
          shrinked={slideShrinked}
          {...(currentSlide === s && {ref: currentCardRef})}
          title="What about large datasets?"
          subtitle="To scale to the global scale datasets we need tiling"
          text="For quadkeys we can simply create a tileset using the standard Mercator x/y/z indexing scheme"
          code={
`new TileLayer({
  data: 'data/{z}/{x}/{y}.json',
  renderSubLayers: props => new QuadkeyLayer(props)
});`}
        />
        <SidebarSlide
          slide={++s}
          shrinked={slideShrinked}
          {...(currentSlide === s && {ref: currentCardRef})}
          title="A better solution"
          subtitle="Messy to use two indexing schemes, better to use just one"
          text="Acheive this by extending the Tileset2D class"
          code={
`new TileLayer({
  data: 'data/{i}.json',
  TilesetClass: QuadkeyTileset2D,
  renderSubLayers: props => new QuadkeyLayer(props)
});`}
        />
        <SidebarSlide
          slide={++s}
          shrinked={slideShrinked}
          {...(currentSlide === s && {ref: currentCardRef})}
          title="Custom Tileset"
          subtitle="Extract logic of tile hierarchy from rendering logic"
          text="Only need to provide methods for getting tiles in viewport, and relationship between tiles"
          code={
`class QuadkeyTileset2D extends Tileset2D {
   getTileIndices(opts) {
     // opts contains viewport
     const xyz = super.getTileIndices(opts);
     return xyz.map(xyzToQuadkey);
   }
  
   getTileId({q}) {
     return q;
   }
  
   getTileZoom({q}) {
     return q.length;
   }
  
   getParentIndex(index) {
     const q = index.q.slice(0, -1);
     return {q};
   }
}`}
  
        />
        <SidebarSlide
          slide={++s}
          shrinked={slideShrinked}
          {...(currentSlide === s && {ref: currentCardRef})}
          title="What about H3?"
          subtitle="Compared to Quadkeys this is harder"
          text="No one-to-one mapping with map tiles and have hexagons* rather than squares"
        />
        <SidebarSlide
          slide={++s}
          shrinked={slideShrinked}
          {...(currentSlide === s && {ref: currentCardRef})}
          title="H3 rendering is now easy*"
          subtitle="Can now create a layer which has tiles of irregular shape under the Mercartor projection"
          text="*if you don't count 100s of hours of work across many teams"
          code={
`import 'h3-js';

class H3Tileset2D extends Tileset2D {
   getTileIndices(opts) {
     return h3IndicesInViewport(opts)
   }
  
   getTileId({h3}) {
     return h3;
   }
  
   getTileZoom({h3}) {
     return h3GetResolution(h3);
   }
  
   getParentIndex(h3) {
     const h3 = h3ToParent(index);
     return {h3};
   }
}`}
        />
        <SidebarSlide
          slide={++s}
          shrinked={slideShrinked}
          {...(currentSlide === s && {ref: currentCardRef})}
          title="Filling viewport with hexagons"
          subtitle="A little bit involved but most of heavy lifting done by h3-js"
          text="Key for good behavior is estimating the h3 resolution, not trivial as hexagon size depends on latitude"
        />
        <SidebarSlide
          slide={++s}
          shrinked={slideShrinked}
          {...(currentSlide === s && {ref: currentCardRef})}
          title="Why is H3 being difficult?"
          subtitle="Surely quadkeys are better if they fit on the map neatly?"
          text="H3 cells are equal size when viewed on a globe"
        />
        <SidebarSlide
          slide={++s}
          shrinked={slideShrinked}
          {...(currentSlide === s && {ref: currentCardRef})}
          title="Why is H3 being difficult?"
          subtitle="Surely quadkeys are better if they fit on the map neatly?"
          text="It is the Mercartor projection that is the problem!"
        />
        <SidebarSlide
          slide={++s}
          shrinked={slideShrinked}
          {...(currentSlide === s && {ref: currentCardRef})}
          title="Working with H3/Quadkey tilesets"
          subtitle="Extension to tilejson allows seamless integration"
          text="Scheme is set to 'h3' or 'quadkey' to indicate use of spatial index"
          code={
`{
  "tilejson": "3.1.0", // Not part of spec!
  "name": "carto-data.public.table",
  "scheme": "h3",
  "tiles": [
    "api.carto.com/v3/maps/bigquery/table/{i}?..."
  ]
  ...
}`}
        />
        <SidebarSlide
          slide={++s}
          shrinked={slideShrinked}
          {...(currentSlide === s && {ref: currentCardRef})}
          title="CartoLayer integration"
          subtitle="Spatial index details hidden from user"
          text="Just need to specify tileset name and it will work"
          code={
`new CartoLayer({
  connection: 'bigquery',
  data: 'carto-data.tilesets.h3_tileset',
  type: MAP_TYPES.TILESET,
  ...
})`}
        />
        <SidebarSlide
          slide={++s}
          shrinked={slideShrinked}
          {...(currentSlide === s && {ref: currentCardRef})}
          title="CartoLayer integration"
          subtitle="Did someone say Dynamic Tiling?"
          text="Industry first: cloud native data, dynamically tiled and aggregated on the fly"
          code={
`new CartoLayer({
  connection: 'bigquery',
  data: 'carto-data.tables.h3_table',
  type: MAP_TYPES.TABLE,
  aggregationExp: 'sum(population) as pop',
  aggregationResLevel: 4,
  ...
  getElevation: ({pop}) => pop,
})`}
        />
        <SidebarSlide
          slide={++s}
          shrinked={slideShrinked}
          {...(currentSlide === s && {ref: currentCardRef})}
          title="CartoLayer integration"
          subtitle="Did someone say Dynamic Tiling?"
          text="Can aggregate multiple parameters via SQL (injection attacks 100% supported)"
          code={
`new CartoLayer({
  connection: 'bigquery',
  data: 'carto-data.tables.h3_table',
  type: MAP_TYPES.TABLE,
  aggregationExp:
    'sum(population) as pop, avg(population) as avg',
  aggregationResLevel: 4,
  ...
  getElevation: ({avg}) => avg,
})`}
        />
      </div>

      <div className={classes.footer}>
        <Fade in={currentSlide === 1}>
          <div>
            <Hidden smDown>
              <Button
                data-position="left"
                classes={{root: classes.footerItem}}
                startIcon={<IconActionHome />}
                color="primary"
                onClick={reset}
              >
                Home
              </Button>
            </Hidden>
            <Hidden mdUp>
              <IconButton
                data-position="left"
                classes={{root: classes.footerItem}}
                color="primary"
                onClick={reset}
              >
                <IconActionHome />
              </IconButton>
            </Hidden>
          </div>
        </Fade>
        <Fade in={currentSlide > 1}>
          <IconButton
            data-position="left"
            classes={{root: classes.footerItem}}
            aria-label="Previous"
            color="primary"
            onClick={prev}
          >
            <ArrowLeft />
          </IconButton>
        </Fade>
        <div className={[classes.dots, classes.footerItem].join(' ')} data-position="center">
          {[...new Array(slidesNumber - 1)].map((item, i) => (
            <div
              key={`dot-${i + 1}`}
              className={classes.dot}
              data-active={i + 1 === currentSlide}
            />
          ))}
        </div>
        <Fade in={currentSlide !== slidesNumber - 1}>
          <div>
            <Hidden smDown>
              <Button
                data-position="right"
                classes={{root: classes.footerItem}}
                variant="contained"
                color="primary"
                onClick={next}
                endIcon={<ArrowRightWhite />}
              >
                Next
              </Button>
            </Hidden>
            <Hidden mdUp>
              <IconButton
                data-position="right"
                classes={{root: [classes.footerItem, classes.iconButtonContained].join(' ')}}
                color="primary"
                onClick={next}
              >
                <ArrowRightWhite />
              </IconButton>
            </Hidden>
          </div>
        </Fade>
      </div>

      <div className={classes.swipeUpRoot}>
        <IconButton
          onClick={() => {
            setMobileExpanded(true);
          }}
          size="small"
        >
          <IconExpand />
        </IconButton>
      </div>
    </Drawer>
  );
};

export default Sidebar;
