import {CartoLayer, colorBins, MAP_TYPES} from '@deck.gl/carto';
import {getH3WorldLayer} from './h3-world';

// const accessToken = 'eyJhbGciOiJIUzI1NiJ9.eyJhIjoiYWNfN3hoZnd5bWwiLCJqdGkiOiI5OTE5MTlmOSJ9.HNk0xPiPlMvUV_oRcV0t4j2hsqfhB7SqFfrH26O3s-E'
const accessToken = 'XXX';

const domain = [1e2, 1e3, 1e4, 5e4, 1e5, 5e5, 1e6];

export function getCartoLayer(addH3, data) {
  return function(layers) {

    // data = 'carto-dev-data.public.derived_spatialfeatures_esp_h3res12_v1_yearly_v2_interpolated';
    // data = 'carto-dev-data.public.derived_spatialfeatures_usa_quadgrid19_v1_yearly_v2_only_pop_interpolated';

    const isH3 = data.includes('h3');
    const type = data.includes('tileset') ? MAP_TYPES.TILESET : MAP_TYPES.TABLE;
    const geoColumn = isH3 ? 'h3' : data.endsWith('_quadkey') ? 'quadkey' : 'quadint';
    const colors = data.includes('ernesto' ) ? 'Sunset' : isH3 ? 'Temps' : 'PurpOr';

    const p = {}
    if (type === MAP_TYPES.TABLE) {
      p.geoColumn = geoColumn;
      p.aggregationExp = 'sum(population) as pop';
      // Global
      // data = 'cartodb-on-gcp-pm-team.ernesto.gbif_occurrences_h3_r12'
      // p.aggregationExp = 'sum(total_occurrences_count) as pop';
      p.aggregationResLevel = isH3 ? 4 : 6;
    }

    const layer = new CartoLayer({
      id: 'carto',
      connection: 'bigquery',
      data,
      type, 

      ...p,

      credentials: {accessToken},

      // getFillColor: ({properties}) => {
      //   const v = 0.01 * properties.pop;
      //   return [255 * v, 163 * v, 255 * (1 - v), 180];
      // },
      getFillColor: colorBins({attr: 'pop', domain, colors}),
      getElevation: ({properties}) => Math.sqrt(properties.pop),
      elevationScale: 333,

      updateTriggers: {
        getElevation: [Math.random()] // Hack
      },
      transitions: {
        getElevation: {type: 'spring', stiffness: 0.005, damping: 0.075}
      }
    });

    // let out = [layer];
    // if (layers) {
    //   out = out.concat(layers);
    // }
    let out = [layer];
    if (addH3) {
       out = out.concat(getH3WorldLayer(layers));
    }
    return out;
  };
}
