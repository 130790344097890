import {CompositeLayer, WebMercatorViewport} from '@deck.gl/core';
import {PathLayer, TextLayer} from '@deck.gl/layers';
import {TileLayer} from '@deck.gl/geo-layers';

import {getQuadkeyLayer} from './quadkey';

class QuadkeyWorldLayer extends CompositeLayer {
  // Force update layer and re-render sub layers when viewport changes
  shouldUpdateState({changeFlags}) {
    return changeFlags.somethingChanged;
  }

  renderLayers() {
    const {viewport} = this.context;
    const {showQuad} = this.props;
    return createQuadkeyLayers(viewport, showQuad);
  }
}

export function getQuadkeyWorldLayer(showQuad) {
  return function getCartoLayer(layers) {
    let out = [new QuadkeyWorldLayer({showQuad}), getQuadkeyLayer(layers)];
    return out;
  }
};

// Style
const COLOR1 = [128, 189, 164];
const COLOR2 = [255, 41, 95];

function tileToQuadkey(tile) {
  let q = '';
  for (let z = tile.z; z > 0; z--) {
    let b = 0;
    const mask = 1 << (z - 1);
    if ((tile.x & mask) !== 0) b++;
    if ((tile.y & mask) !== 0) b += 2;
    q += b.toString();
  }
  return q;
}

// Visualization
function createQuadkeyLayers(viewState, showQuad) {
  const resolution = viewState.zoom;
  const r = Math.floor(resolution);
  
  return new TileLayer({
    id: `tile-${r}`,
    data: './assets/logo.svg',
    tileSize: 256,

    widthMinPixels: 5,
    getColor: COLOR1,

    updateTriggers: {
      renderSubLayers: [showQuad]
    },

    renderSubLayers: props => {
      const {tile} = props;
      const {bbox: {west, south, east, north}, id, index} = tile;
      const {x, y, z} = index;
      const xyz = `z:${z}\nx:${x}\ny:${y}`;
      const quadkey = tileToQuadkey(index);

      return [
        new PathLayer({
          ...props,
          id: `tile-${r}-${id}`,
          data: [0],

          getPath: d => [
            [east, north], [east, south], [west, south], [west, north], [east, north]
          ],
        }),
        new TextLayer({
          ...props,
          id: `tile-text-${r}-${showQuad ? id : quadkey}`,
          data: [0],

          getPosition: [0.5 * (east + west), 0.5 * (north + south)],
          getText: showQuad ? d => quadkey : d => xyz,
          sizeScale: showQuad ? Math.min(1, 10 / quadkey.length) : 0.5
        })
      ];
    }
  });
}
