import React, {useCallback, useRef, useState, createContext, useContext, useEffect} from 'react';
import {LinearInterpolator, FlyToInterpolator} from '@deck.gl/core';
import {BASEMAP as bs, fetchMap, setDefaultCredentials} from '@deck.gl/carto';

import slides from './slides';

const BASEMAP = './assets/darkmatter-high-contrast.json';
const BASEMAP2 = bs.DARK_MATTER_NOLABELS;

const hash = window.location.hash;

const initAppState = {
  currentSlide: hash !== '' ? parseInt(hash.slice(1)) : 0,
  viewState: {latitude: 40.4814, longitude: 3.3765, zoom: 7},
};

export const AppStateContext = createContext(initAppState);

const transitionInterpolator = new LinearInterpolator(['bearing', 'longitude', 'latitude']);

export const AppStateStore = ({children}) => {
  const [currentSlide, setCurrentSlide] = useState(initAppState.currentSlide);
  const [layers, setLayers] = useState([]);
  const [viewState, setViewState] = useState(initAppState.viewState);
  window.location.hash = `#${currentSlide || 0}`;

  const [animationFlag, setAnimationFlag] = React.useState();
  const forceUpdate = React.useCallback(() => setAnimationFlag({}), []);

  // Animation using the TripsLayer
  const maxTime = 50000;
  let currentTime = useRef(0);
  let requestRef = useRef(null);

  const orbit = useCallback(() => {
    setViewState((viewState) => ({
      ...viewState,
      bearing: viewState.bearing + 120,
      transitionDuration: 20000,
      transitionInterpolator,
      onTransitionEnd: orbit
    }));
  }, []);

  const updateViewState = function (viewState, shouldOrbit) {
    setViewState({
      transitionDuration: 5000,
      ...viewState,
      transitionInterpolator: new FlyToInterpolator(),
      onTransitionEnd: () => {
        if (shouldOrbit) {
          orbit();
        }
      }
    });
  };

  useEffect(async () => {
    setDefaultCredentials({
      accessToken:
        'eyJhbGciOiJIUzI1NiJ9.eyJhIjoiYWNfbHFlM3p3Z3UiLCJqdGkiOiI1YjI0OWE2ZCJ9.Y7zB30NJFzq5fPv8W5nkoH5lPXFWQP0uywDtqUg8y8c'
    });

    setCurrentSlide(initAppState.currentSlide);
  }, [setCurrentSlide]);

  useEffect(() => {
    if (currentSlide !== null) {
      const {
        mapID: cartoMapId,
        view,
        orbit: shouldOrbit,
        generator,
        parameters,
        props,
        embed,
        image
      } = slides[currentSlide];
      if (embed === undefined && image === undefined) {
        if (cartoMapId) {
          fetchMap({cartoMapId}).then(
            ({initialViewState: mapViewState, mapStyle, layers: mapLayers}) => {
              let _mapLayers = mapLayers.map((l) =>
                l.clone({
                  parameters: {...l.props.parameters, ...parameters},
                  ...props
                })
              );
              if (generator) {
                _mapLayers = generator(_mapLayers);
              }

              setLayers(_mapLayers);
              updateViewState(mapViewState, shouldOrbit);
            }
          );
        } else if (generator) {
          const mapLayers = generator().flat();
          let _mapLayers = mapLayers.map((l) =>
            l.clone({
              parameters: {...l.props.parameters, ...parameters},
              ...props
            })
          );
          setLayers(_mapLayers);
        }

        if (view && view.longitude !== undefined) {
          updateViewState({latitude: 0, longitude: 0, zoom: 0, bearing: 0, pitch: 0, ...view}, shouldOrbit);
        }
      }
    }
  }, [currentSlide]);

  // Animation
  const animate = () => {
    currentTime.current = (currentTime.current + 10) % maxTime;
    if (layers.length > 0 && currentSlide <= 1) {
      setLayers(layers.map(l => l.clone({currentTime: currentTime.current})));
      requestRef.current = requestAnimationFrame(animate);
    }
  }
  
  // React.useEffect(() => {
  //   if (currentSlide <= 1) {
  //     requestRef.current = requestAnimationFrame(animate);
  //   }
  //   return () => cancelAnimationFrame(requestRef.current);
  // }, [currentSlide, animationFlag]); 

  return (
    <AppStateContext.Provider
      value={{
        next: () => {
          if (currentSlide < slides.length - 1) {
            setCurrentSlide(currentSlide + 1);
          }
        },
        prev: () => {
          if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
          }
        },
        reset: () => {
          setCurrentSlide(0);
        },
        currentSlide,
        layers,
        slidesNumber: slides.length,
        viewState,
        basemap: BASEMAP
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

export const AppStateContextConsumer = AppStateContext.Consumer;

export function useAppState() {
  return useContext(AppStateContext);
}
