import {getMaskedLayer} from './layers/masked';
import {getAlcalaLayer} from './layers/alcala';
import {getCartoLayer} from './layers/carto';
import {getH3WorldLayer} from './layers/h3-world';
import {getQuadkeyLayer} from './layers/quadkey';
import {getQuadkeyWorldLayer} from './layers/quadkey-world';

export default [
  /* 0 */ {
    generator: getMaskedLayer
  },
  /* 1 */ {
    generator: getMaskedLayer,
    view: {latitude: 0, longitude: 0, zoom: 1, bearing: 0, pitch: 30, transitionDuration: 50000},
  },
  /* 2 - San Francisco static quadkey */ {
    generator: getQuadkeyLayer,
    view: {latitude: 37.75, longitude: -122.45, zoom: 11.5, bearing: 80, pitch: 50, transitionDuration: 5000},
    props: {getElevation: 0},
    orbit: true
  },
  /* 3 - San Francisco quadkey extruded */ { 
    generator: getQuadkeyLayer,
  },
  /* 4 - Quadkey world */ {
    generator: getQuadkeyWorldLayer(false),
  },
  /* 5 - Quadkey world zoom out (quadkey labels) */ {
    generator: getQuadkeyWorldLayer(true),
  },
  /* 6 - Quadkey world zoom out full (quadkey labels) */ {
    generator: getQuadkeyWorldLayer(true),
    view: {latitude: 0, longitude: 0, zoom: 0, transitionDuration: 2000}
  },
  /* 7 - H3 World */ {
    generator: getH3WorldLayer,
    view: {latitude: 0, longitude: 0, zoom: 0, transitionDuration: 5000}
  },
  /* 8 - H3 + Alcala */ {
    generator: getAlcalaLayer,
    view: {latitude: 40.4814, longitude: -3.3765, zoom: 18, bearing: 280, pitch: 50, transitionDuration: 7000},
    orbit: true
  },
  /* 9 - H3 + Alcala top down */ {
    generator: getAlcalaLayer,
    view: {latitude: 40.4814, longitude: -3.3765, zoom: 16, bearing: 0, pitch: 0, transitionDuration: 3000}
  },
  /* 10 - H3 world image */ {
    image: 'h3-world.jpg'
  },
  /* 11 - H3 world image flat */ {
    image: 'h3-world-flat.jpg'
  },
  // DEMOS
  /* 12 - H3 tileset with hexagons */ {
    generator: getCartoLayer(true, 'carto-dev-data.tilesets.derived_spatialfeatures_esp_h3res8_v1_yearly_v2_tileset'),
    props: {getElevation: 0},
    view: {latitude: 40, longitude: -3.3765, zoom: 5.5, bearing: 0, pitch: 30, transitionDuration: 1000},
  },
  /* 13 - H3 tileset + hexagons gone */ {
    generator: getCartoLayer(false, 'carto-dev-data.tilesets.derived_spatialfeatures_esp_h3res8_v1_yearly_v2_tileset'),
    view: {latitude: 40, longitude: -3.3765, zoom: 5.5, bearing: 0, pitch: 50, transitionDuration: 2000},
    props: {getElevation: 0},
    orbit: true
  },
  /* 14 - H3 table + extrude */ {
    generator: getCartoLayer(false, 'carto-dev-data.public.derived_spatialfeatures_esp_h3res12_v1_yearly_v2_interpolated'),
    view: {latitude: 40, longitude: -3.3765, zoom: 5.5, bearing: 0, pitch: 50, transitionDuration: 2000},
  },
  /* 15 - H3 avg aggregation + extrude */ {
    generator: getCartoLayer(false, 'carto-dev-data.public.derived_spatialfeatures_esp_h3res12_v1_yearly_v2_interpolated'),
    props: {getElevation: ({avg}) => avg, aggregationExp: 'sum(population) as pop, avg(population) as avg'},
    view: {latitude: 40, longitude: -3.3765, zoom: 5.5, bearing: 0, pitch: 50, transitionDuration: 2000},
  },
  /* 16 - CartoLayer quadkey */ {
    generator: getCartoLayer(false, 'carto-dev-data.public.derived_spatialfeatures_usa_quadgrid19_v1_yearly_v2_only_pop_interpolated'),
    view: {latitude: 40, longitude: -100, zoom: 3, bearing: 0, pitch: 10, transitionDuration: 5000},
  },
];
