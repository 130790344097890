import {geoToH3, getRes0Indexes, h3ToParent, kRing, polyfill} from 'h3-js';
import {CompositeLayer, WebMercatorViewport} from '@deck.gl/core';
import {H3HexagonLayer} from '@deck.gl/geo-layers';

class H3WorldLayer extends CompositeLayer {
  // Force update layer and re-render sub layers when viewport changes
  shouldUpdateState({changeFlags}) {
    return changeFlags.somethingChanged;
  }

  renderLayers() {
    const {viewport} = this.context;
    return createHexagonLayers(viewport);
  }
}

export function getH3WorldLayer(layers) {
  let out = [new H3WorldLayer];
  if (layers) {
    out = out.concat(layers);
  }
  return out;
};

// Relative scale factor (0 = no biasing, 2 = a few hexagons cover view)
const bias = 2;

// Resolution conversion function. Takes a WebMercatorViewport and returns
// a H3 resolution such that the screen space size of the hexagons is
// similar
function getHexagonResolution(viewport) {
  const hexagonScaleFactor = (2 / 3) * viewport.zoom;
  const latitudeScaleFactor = Math.log(1 / Math.cos(Math.PI * viewport.latitude / 180));

  // Clip and bias
  const r = hexagonScaleFactor + latitudeScaleFactor - bias;
  return Math.max(0, Math.min(15, r));
}

// Style
const COLOR1 = [128, 189, 164];
const COLOR2 = [255, 41, 95];

// Visualization
function createHexagonLayers(viewState) {
  const resolution = getHexagonResolution(viewState);
  const r0 = Math.floor(resolution);
  const r1 = r0 + 1;
  
  const rRef = Math.round(resolution);
  const hexagons = getHexagonsInView(viewState, rRef);
  
  // Stats display
  const totalHex = 122 * Math.pow(7, rRef); // estimate
  const el = document.getElementById('textfield');
  // el.innerHTML = `Hexagons: ${hexagons.length}/${totalHex}, resolution: ${rRef}`;

  return [r0, r1].map(r => {
    const alpha = Math.pow(1 - Math.abs(r - resolution), 1.5);
    const color = Math.floor(r) % 2 ? COLOR1 : COLOR2;
    return new H3HexagonLayer({
      id: `hex-${r}`,
      data: getHexagonsInView(viewState, r),
      getHexagon: d => d,
      getLineColor: [...color, 255 * alpha],

      extruded: false,
      filled: false,
      stroked: true,
      getLineWidth: 5,
      lineWidthUnits: 'pixels',
    });
  });
}

// H3 helpers
function getHexagonsInView(view, resolution) {
  const viewport = new WebMercatorViewport(view);
  if (resolution >= 15) {
    const center = geoToH3(viewport.latitude, viewport.longitude, 15);
    return kRing(center, 1);
  }

  const [east, south, west, north] = viewport.getBounds();
  const out = getHexagonsInBoundingBox({
    east,
    south,
    west,
    north
  }, resolution);
  return out;
}

function getHexagonsInBoundingBox({
  west,
  north,
  east,
  south
}, resolution) {
  if (resolution === 0) {
    return getRes0Indexes();
  }
  
  if (east - west > 180) {
    // This is a known issue in h3-js: polyfill does not work correctly
    // when longitude span is larger than 180 degrees.
    return getHexagonsInBoundingBox({
      west,
      north,
      east: 0,
      south
    }, resolution).concat(
      getHexagonsInBoundingBox({
        west: 0,
        north,
        east,
        south
      }, resolution)
    );
  }

  // `polyfill()` fills based on hexagon center, which means tiles vanish
  // prematurely. Get more accurate coverage by oversampling
  const oversample = 1;
  let h3Indices = polyfill(
    [
      [
        [west, north],
        [west, south],
        [east, south],
        [east, north],
        [west, north]
      ]
    ],
    resolution + oversample,
    true
  );

  h3Indices = [...new Set(h3Indices.map(i => h3ToParent(i, resolution)))];
  h3Indices.sort();
  
  return h3Indices;
}

