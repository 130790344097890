import GL from '@luma.gl/constants';

import {BitmapLayer} from '@deck.gl/layers';
import {MaskExtension} from '@deck.gl/extensions';

import {getCartoLayer} from './carto';

export function getMaskedLayer(layers) {
  let cartoLayer = getCartoLayer(false, 'cartodb-on-gcp-pm-team.ernesto.gbif_occurrences_h3_r12')(layers);
  cartoLayer = cartoLayer.map(l => l.clone({
    aggregationExp: 'sum(total_occurrences_count) as pop',
    maskId: 'geofence',
    extensions: [new MaskExtension()],
  }));

  const maskLayer = new BitmapLayer({
		id: 'geofence',
    bounds: [-170, -60, 170, 60],
    image: './assets/logo.svg',

		operation: 'mask',
    parameters: {
      blendFunc: [GL.SRC_ALPHA, GL.DST_ALPHA],
      blendEquation: GL.FUNC_ADD
    },
  });

	return [cartoLayer, maskLayer];
};
