import {QuadkeyLayer} from '@deck.gl/geo-layers';

export function getQuadkeyLayer(layers) {
  const quadkey = new QuadkeyLayer({
    id: 'quadkey',
    data: 'https://raw.githubusercontent.com/visgl/deck.gl-data/2725c5ec1e8e2824b0dd031b53b1e21664cb1d57/website/sf.quadkeys.json',

    extruded: true,
    wireframe: true,
    getFillColor: ({value: v}) => [255 * v, 163 * v, 255 * (1 - v), 180],
    getElevation: ({value}) => value,
    elevationScale: 5000,

    updateTriggers: {
      getElevation: [Math.random()] // Hack
    },
    transitions: {
      getElevation: {type: 'spring', stiffness: 0.005, damping: 0.075}
    }
  });

  let out = [quadkey];
  return out;
};
