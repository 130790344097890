import {GeoJsonLayer} from '@deck.gl/layers';
import {HeatmapLayer} from '@deck.gl/aggregation-layers';
import {getH3WorldLayer} from './h3-world';

export function getAlcalaLayer(layers) {
  const alcala = [
    // new HeatmapLayer({
    //   id: 'heatmap',
    //   data: PEOPLE.features,
    //   getPosition: d => d.geometry.coordinates,
    //   getWeight: d => d.properties.count || 1,
    //   aggregation: 'SUM'
    // }),
    new GeoJsonLayer({
      id: 'alcala',
      data: ALCALA,

      extruded: true,
      wireframe: true,
      getFillColor: [255, 233, 255, 70],
      getElevation: 10
    })
  ]

  let out = [alcala, getH3WorldLayer(layers)];
  return out;
};

const ALCALA = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -3.3763499557971954,
              40.481049579491845
            ],
            [
              -3.376481384038925,
              40.4810220375453
            ],
            [
              -3.3767563104629517,
              40.48181054880538
            ],
            [
              -3.376556485891342,
              40.48185033114616
            ],
            [
              -3.3764706552028656,
              40.481601436113635
            ],
            [
              -3.376378118991852,
              40.481620817317335
            ],
            [
              -3.376285582780838,
              40.481354580292795
            ],
            [
              -3.376372754573822,
              40.48133723914727
            ],
            [
              -3.3762936294078827,
              40.481105683422115
            ],
            [
              -3.3763647079467773,
              40.481089362283605
            ],
            [
              -3.3763499557971954,
              40.481049579491845
            ]
          ]]
      }
    }
  ]
}

const PEOPLE = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "count": 3
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3763499557971954,
          40.481049579491845
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "count": 2
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3764156699180603,
          40.48157899471288
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "count": 30
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3764196932315826,
          40.48152697143685
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "count": 30
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.376375436782837,
          40.48149738955601
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "count": 30
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.376394212245941,
          40.481455566874665
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "count": 30
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3763459324836727,
          40.48143516555724
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "count": 30
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3763808012008667,
          40.481417824432555
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "count": 20
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3763056993484493,
          40.481362740830306
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "count": 10
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.376372754573822,
          40.48155349311201
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "count": 2
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3776548504829407,
          40.481332138809506
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "count": 1
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.377925753593445,
          40.481160767235316
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "count": 3
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.377630710601806,
          40.48247868499136
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3764129877090454,
          40.48126481431468
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3765846490859985,
          40.48147494812048
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.376544415950775,
          40.48166263989573
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3765926957130428,
          40.48172180349871
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.376453220844269,
          40.481175048216535
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3764398097991943,
          40.48115260667325
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3761125802993774,
          40.48103835870019
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.376769721508026,
          40.48091799008964
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3762413263320923,
          40.48118116863613
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3762869238853455,
          40.48126685445189
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.376949429512024,
          40.4818646119807
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3770084381103516,
          40.4818564515042
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.376252055168152,
          40.480795581111806
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3761849999427795,
          40.480797621263264
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3762225508689876,
          40.48081598262361
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.37635800242424,
          40.481371411400325
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3763298392295837,
          40.48140558363597
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3763600140810013,
          40.48141272410092
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3763466030359264,
          40.4814667875966
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3764002472162247,
          40.48148310864336
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3763526380062103,
          40.481520341016406
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.376378118991852,
          40.48152391124288
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.376366719603538,
          40.481444346150845
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3763211220502853,
          40.48138365220314
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.376368060708046,
          40.48139385287046
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.376404270529747,
          40.48152085104876
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3763975650072093,
          40.48154329246897
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3763492852449417,
          40.4814922892304
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.3764123171567917,
          40.48150606010864
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          -3.376370072364807,
          40.48147086785868
        ]
      }
    }
  ]
}
